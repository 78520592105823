/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getImage, GatsbyImage} from 'gatsby-plugin-image';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    blockquote: "blockquote"
  }, _provideComponents(), props.components), {Box} = _components;
  if (!Box) _missingMdxReference("Box", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Harva on onnistunut elämään yritysmaailmassa 2010-luvun törmäämättä Simon Sinekin Start with Why ", React.createElement(_components.a, {
    href: "https://www.ted.com/talks/simon_sinek_how_great_leaders_inspire_action"
  }, "TED-puheeseen"), " tai kirjaan. Sinekin mukaan kaikkia pitkään menestyviä yrityksiä, kansanliikkeitä ja ihmisiä ajaa vahva “Miksi”. Vasta toissijaisia ovat Miten ja Mitä, ja ne voivat myös muuttua ajan myötä, vaikka perustarkoitus Miksi pysyy. Niin organisaation olemassaolon tarkoitus kuin johtaminenkin pitää lähteä Miksi-kysymyksestä, ja sen pitää välittyä yhtä vahvasti myös asiakkaille tai muille stakeholdereille."), "\n", React.createElement(_components.p, null, "Malli kiteytyy “kultaiseen kehään”, jossa Miksi on kaiken ytimessä, sen ympärille muodostuu Miten ja uloimmalle kehälle Mitä."), "\n", React.createElement(Box, {
    textAlign: "center"
  }, React.createElement(GatsbyImage, {
    image: getImage(props.data.mdx.frontmatter.images[0]),
    alt: "Start with Why - Kysy ensin miksi"
  })), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Kuten kaikki yksinkertaistukset, tämäkin pitää ottaa hyppysellisellä suolaa. Vahva merkityksellisyys ei ole tae menestyksestä, ja moni yritys tai ihminen menestyy hyvin kyseenalaisillakin motiiveilla ja olemassaolon tarkoituksilla."), "\n", React.createElement(_components.p, null, "Mutta, kuten kaikki yksinkertaistukset, Start with Why on työkalu ja sellaisena erinomainen."), "\n"), "\n", React.createElement(_components.p, null, "Itselleni tämän kaltaiset mallit ovat aina parhaimmillaan reflektointi- ja fasilitointityökaluina niin itselle kuin asiakkaiden kanssa. Miksi-Miten-Mitä -jako johdattaa parhaimmillaan keskustelun yrityksen (tai yksilön) missiosta ja visiosta normaalia syvemmälle. Erityisen herkullista keskustelua saattaa syntyä, jos reflektointia voi käydä yrittäjän kanssa sekä hänen henkilökohtaisesta että yrityksen näkökulmasta."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
